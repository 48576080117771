import React from 'react';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import {FooterContainer, 
    FooterWrap, 
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink    
} from './FooterElements'
import {animateScroll as scroll} from 'react-scroll'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                {/*
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>CodeChemist</FooterLinkTitle>
                                    <FooterLink to="/about">About</FooterLink>
                                    <FooterLink to="/contact">Contact</FooterLink>
                            </FooterLinkItems>
                            <FooterLinkItems>
                                <FooterLinkTitle>About Me</FooterLinkTitle>
                                    <FooterLink to="/about">LinkedIn</FooterLink>
                                    <FooterLink to="/about">Github</FooterLink>
                            </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                */}
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            CodeChemist
                        </SocialLogo>
                        <WebsiteRights>CodeChemist © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//www.github.com/NealRyan" target="_blank" aria-label="Github">
                                <FaGithub/>
                            </SocialIconLink>
                            <SocialIconLink href="//www.linkedin.com/in/neal-ryan" target="_blank" aria-label="Linkedin">
                                <FaLinkedin/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
