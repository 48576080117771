import styled from 'styled-components';

export const TextfieldContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    height: 50%;
    width: 100%;
    position: relative;
    z-index: 1;


    textarea {
        height: 10rem;
        width: 30rem;
        margin-top: 20%;

        @media screen and (max-width: 768px) {
            margin-top: 50%;
            width: 100%;
        }   
        
        @media screen and (max-width: 400px) {
            margin-top: 50%;
            width: 100%;
        }
    }
`;

export const ButtonContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 20%;
    width: 100%;
    position: relative;
    z-index: 1;
`;

export const SentimentContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 20%;
    width: 100%;
    position: relative;
    z-index: 1;
`;