export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    headline: "Hi, I'm Neal",
    description: <code>
    I'm a coder and a chemist, and this site exists to show off a few of my coding projects. More projects can be found on my github.
    <br />
    <br />
    A little more about me: I love chess, cars, snowboarding, hiking, watches, and games (video, board, or card).</code>,
    buttonLabel: "See my Resume",
    buttonLabel2: "< Revert",
    buttonLink: false,
    buttonTo: 'projects',
    imgStart: false,
    img: require('../../images/flask.svg').default,
    alt: 'Reaction',
    img2: require('../../images/Resume.svg').default,
    alt2: 'Resume',
    dark: true,
    primary: true,
    darkText: false,
};
