import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    username: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleVerify = e => {
    setIsVerified(true);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
    emailjs.sendForm('service_7bhaamz', 'template_o8uc2gn', e.target, 'user_qQvdC4evLvKkzW0PaApNK').then(res=>{
      console.log(res);
    }).catch(err=> console.log(err));
  };

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isVerified && isSubmitting) {
        callback();
      }
    },
    [errors]
  );

  return { handleChange, handleVerify, handleSubmit, values, errors };
};

export default useForm;
