import React from 'react';
import ProjectsNavbar from '../components/ProjectsNavbar';
import AtomDisplay from '../components/IndividualProjectCode/atomFiles';
import ProjectInfoSection from '../components/ProjectsInfoSection';
import { projectsObjOne } from '../components/ProjectsInfoSection/Data';
import {animateScroll as scroll} from 'react-scroll';
import Footer from '../components/Footer';



const AtomPage = () => {
    scroll.scrollToTop();
    return (
        <>
            <ProjectsNavbar />
            <AtomDisplay />
            <ProjectInfoSection {...projectsObjOne}/>
            <Footer />
        </>
    )
}

export default AtomPage
