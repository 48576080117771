import React from 'react';
import ProjectsNavbar from '../components/ProjectsNavbar';
import SentimentDisplay from '../components/IndividualProjectCode/SentimentFiles';
import ProjectInfoSection from '../components/ProjectsInfoSection';
import { projectsObjThree, projectsObjFour } from '../components/ProjectsInfoSection/Data';
import {animateScroll as scroll} from 'react-scroll';
import Footer from '../components/Footer';



const SentimentPage = () => {
    scroll.scrollToTop();
    return (
        <>
            <ProjectsNavbar />
            <SentimentDisplay />
            <ProjectInfoSection {...projectsObjThree}/>
            <ProjectInfoSection {...projectsObjFour}/>
            <Footer />
        </>
    )
}

export default SentimentPage