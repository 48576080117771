import React, { Component } from 'react'
import * as THREE from 'three'

let numOfElectrons, point, electronAxis, electronMeshes, camera, renderer;

class Scene extends Component {
  constructor(props) {
    super(props)

    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)
    this.onWindowResize = this.onWindowResize.bind(this)
  }

  componentDidMount() {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      130,
      width / height,
      0.1,
      1000
    )
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    camera.position.z = 15;
    renderer.setClearColor('#000000')
    renderer.setSize(width, height)

    let erad = 0.07;
    numOfElectrons = 2000;
    electronMeshes = [];

    for (let i=0; i < numOfElectrons; i++) {
        //make an electron mesh
        var electron = new THREE.SphereGeometry(erad, 30, 30);
        var material = new THREE.MeshPhongMaterial({   
            color: 0xffbb33,
            metalness: 1,
            shininess: 100
        });
        var meshe = new THREE.Mesh(electron, material);
        
        //place the electron on the surface of a sphere
        //point on sphere x2+y2+z2 = r2
        let total = 10*10;
        let x = Math.random()*Math.sqrt(total);
        total -= x*x;
        let y = Math.random()*Math.sqrt(total);
        total -= y*y;
        let z = Math.sqrt(total);
        x = x * randSign();
        y = y * randSign();
        z = z * randSign();

        meshe.position.set(x,y,z);
        scene.add(meshe);
        electronMeshes[i] = meshe;
    }

    //add 5 protons to the scene
    let prad = 0.8775;
    makeProton(scene, 0, 2*prad, 0);
    makeProton(scene, 2*prad, 0, 0);
    makeProton(scene, 0, 0, 0);
    makeProton(scene, -prad, -prad, prad);
    makeProton(scene, prad, -prad, prad);

    //add 3 neutrons to the scene
    let nrad = 0.8;
    makeNeutron(scene, -2*nrad, 0, 0);
    makeNeutron(scene, 0, -2*nrad, 0);
    makeNeutron(scene, -1, .8, -1);
    makeNeutron(scene, 1, .8, 1);

    var light = new THREE.PointLight(0xFFFFFF, 1, 500);
    light.position.set(0, 0, 25);
    scene.add(light);

    point = new THREE.Vector3(0,0,0);
    electronAxis = [];
    for (let i=0; i < numOfElectrons; i++) {
        //get a random sign and direction;
        let X = Math.random()*randSign();
        let Y = Math.random()*randSign();
        let Z = Math.random()*randSign();
        var axis = new THREE.Vector3(X, Y, Z).normalize();
        electronAxis[i] = axis;
    }

    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.material = material

    window.addEventListener("resize", this.onWindowResize, false);
    this.mount.appendChild(this.renderer.domElement)
    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {
    for (let i=0; i < numOfElectrons; i++) {
      rotateAboutPoint(electronMeshes[i], point, electronAxis[i], THREE.Math.degToRad(1));
    }
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera)
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    return (
      <div
        style = {{ width: '100', height: '700px', background: 'black' }}
        ref={(mount) => { this.mount = mount }}
      />
    )
  }
}

export default Scene


//helper functions

function makeNeutron(scene, x, y, z) {
    let nrad = 0.8;
    var neutron = new THREE.SphereGeometry(nrad, 30, 30);
    var material = new THREE.MeshStandardMaterial({color:0x870505, metalness: 0.5});
    var meshn = new THREE.Mesh(neutron, material);
    meshn.position.set(x, y, z);
    scene.add(meshn);
}

function makeProton(scene, x, y, z) {
    let prad = 0.8775;
    var proton = new THREE.SphereGeometry(prad, 30, 30);
    var material = new THREE.MeshStandardMaterial({color:0x053787, metalness: 0.5});
    var meshp = new THREE.Mesh(proton, material);
    meshp.position.set(x, y, z);
    scene.add(meshp);
}

//this.tl = new TimelineMax({paused: true});
//this.tl.to(this.mesh.scale, 1, {x:2, ease: Expo.easeOut});

// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
// pointIsWorld - boolean indicating the point is in world coordinates (default = false)
function rotateAboutPoint(obj, point, axis, theta, pointIsWorld){
    pointIsWorld = (pointIsWorld === undefined)? false : pointIsWorld;

    if(pointIsWorld){
        obj.parent.localToWorld(obj.position); // compensate for world coordinate
    }

    obj.position.sub(point); // remove the offset
    obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
    obj.position.add(point); // re-add the offset

    if(pointIsWorld){
        obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
    }

    obj.rotateOnAxis(axis, theta); // rotate the OBJECT
};

function randSign() {
    let sign = 1;
    let number = Math.random();
    if (number > 0.5) {
        sign = -1
    }
    return sign
};

