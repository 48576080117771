import React, { useState } from 'react';
//import './Form.css';
import { PageContainer, FormContainer, Img } from './FormElements';
import FormStart from './FormStart';
import FormSuccess from './FormSuccess';
import image from '../../images/mail.svg'
import image2 from '../../images/mail_sent.svg'

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <PageContainer id='contact'>
      <FormContainer>
        {!isSubmitted ? (
          <Img src={image} />
        ) : (
          <Img src={image2} />
        )}
        {!isSubmitted ? (
          <FormStart submitForm={submitForm} />
        ) : (
          <FormSuccess />
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default Form;