import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'



//Logo+text
export const NavLogo = styled(LinkRouter)`
    color: #fff;
    //justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    //display: flex;
    //align-items: center;
    top: 1.5rem;
    left: 4.5rem;
    font-weight: bold;
    text-decoration: none;
    position: absolute;
    z-index: 2;
`;