import React from 'react';
import { FormContentRight } from './FormElements'

const FormSuccess = () => {
  return (
    <FormContentRight>
      <h1 className='form-success'>Message received!</h1>
    </FormContentRight>
  );
};

export default FormSuccess;
