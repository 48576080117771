import styled from 'styled-components';

export const PageContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
`;

export const FormContainer = styled.div`
    background: white;
    background: linear-gradient(
    to right bottom,
    rgba(144, 151, 192, 0.3),
    rgba(144, 151, 192, 0.2),
    rgba(144, 151, 192, 0.5),
    rgba(144, 151, 192, 0.2),
    rgba(144, 151, 192, 0.3)
    );
    border-radius: 2rem;
    backdrop-filter: blur(40px);
    height: 70%;
    width: 65%;
    z-index: 2;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

export const FormContentLeft = styled.div`
`;

export const Img = styled.img`
    top: 30%;
    left: 6%;
    width: 40%;
    height: 50%;
    position: relative;
    z-index: 3;
`;


export const FormContentRight = styled.div`
    z-index: 4;
    border-radius: 10px 0 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 0%;
    left: 25%;

    .form-success {
    text-align: center;
    font-size: 24px;
    color: #fff;
    width: 50%;
    top: 0%;
    }

    .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    .form h1 {
    font-size: 1.5rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
    color: #fff;
    }

    .form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
    }

    .form-inputs p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
    }

    .form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
    }

    .form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
    }

    .form-input::placeholder {
    color: #595959;
    font-size: 12px;
    }

    .form-input-message {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 8rem;
    width: 100%;
    border: none;
    }

    .form-input-message::placeholder {
    color: #595959;
    font-size: 12px;
    }

    .form-input-btn {
    width: 80%;
    height: 3rem;
    margin-top: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 2rem;
    background: linear-gradient(
        to right bottom,
        rgba(1, 191, 112, 0.1),
        rgba(1, 191, 112, 0.8)
    );
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
    z-index: 5;
    }

    .form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient(
        to right bottom,
        rgba(1, 191, 112, 0.8),
        rgba(1, 191, 112, 1)
    );
    transition: all 0.4s ease-out;
    }

    .recaptcha {
    @media screen and (max-width: 768px) {
        transform:scale(0.60)
    }

    @media screen and (max-width: 400px) {
        transform:scale(0.5)
    }
    }
`;