import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import AtomPage from './pages/atom';
import WarpSpeedPage from './pages/warpSpeed';
import SentimentPage from './pages/sentiment';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/atom" component={AtomPage} exact />
        <Route path="/warpSpeed" component={WarpSpeedPage} exact />
        <Route path="/sentiment" component={SentimentPage} exact />
      </Switch>
    </Router>
  );
}

export default App;
