import React from 'react'
import ThreeScene from '../atomFiles/AtomCodeElements'

const AtomDisplay = () => {
    return (
        <>
            <ThreeScene />
        </>
    )
}

export default AtomDisplay

