import React from 'react'
import ThreeScene from '../WarpSpeedFiles/WarpSpeedCodeElements'

const WarpSpeedDisplay = () => {
    return (
        <>
            <ThreeScene />
        </>
    )
}

export default WarpSpeedDisplay

/*
<script src="atomCode.js"></script>
            <script src="three.min.js"></script>
            <script src="postprocessing.min.js"></script>
*/
