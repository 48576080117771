import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import { FormContentRight } from './FormElements';
import ReCAPTCHA from 'react-google-recaptcha';

const FormSignup = ({ submitForm }) => {
  const { handleChange, handleVerify, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
    
  );

  return (
    <FormContentRight>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <h1>
          Contact
        </h1>
        <div className='form-inputs'>
          <label className='form-label'>Name</label>
          <input
            className='form-input'
            type='text'
            name='username'
            placeholder='Enter your name'
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>Email</label>
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder='Enter your email'
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>Message</label>
          <textarea
            className='form-input-message'
            type='text'
            name='message'
            placeholder='Enter your Message'
            value={values.message}
            onChange={handleChange}
          />
          {errors.message && <p>{errors.message}</p>}
        </div>
        <div className='recaptcha'>
          <ReCAPTCHA 
            sitekey="6Le1t5YcAAAAAOc3dxmfC6Q_DJaUWQgFvUmqJ4cw"
            onChange={handleVerify}
          />
        </div>
        <button className='form-input-btn' type='submit'>
          Submit
        </button>
      </form>
    </FormContentRight>
  );
};

export default FormSignup;
