import React from 'react';
import ProjectsNavbar from '../components/ProjectsNavbar';
import ProjectInfoSection from '../components/ProjectsInfoSection';
import { projectsObjTwo } from '../components/ProjectsInfoSection/Data';
import {animateScroll as scroll} from 'react-scroll';
import Footer from '../components/Footer';
import WarpSpeedDisplay from '../components/IndividualProjectCode/WarpSpeedFiles';



const WarpSpeedPage = () => {
    scroll.scrollToTop();
    return (
        <>
            <ProjectsNavbar />
            <WarpSpeedDisplay />
            <ProjectInfoSection {...projectsObjTwo}/>
            <Footer />
        </>
    )
}

export default WarpSpeedPage

//<script src="three.min.js"></script>
//<script src="postprocessing.min.js"></script>
//<script src="./projectsPage/IndividualProjectCode/atomFiles/atomCode.js"></script>
//<script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/104/three.js"></script>
//<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.2/TweenMax.min.js"></script>