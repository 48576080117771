import React from 'react';
import atom from '../../images/atom.png'
import rocket from '../../images/rocket.svg'
import data from '../../images/input_data.svg'
import {ProjectsContainer, ProjectsH1, ProjectsWrapper, ProjectsCard, ProjectsIcon, ProjectsH2, ProjectsP} from './ProjectElements'

const Projects = () => {
    return (
        <ProjectsContainer id="projects">
            <ProjectsH1>Projects</ProjectsH1>
            <ProjectsWrapper>
                <ProjectsCard to='atom'>
                    <ProjectsIcon src={atom} />
                    <ProjectsH2>The Bohr Model</ProjectsH2>
                    <ProjectsP>A first step towards quantam: Visualizing an electron cloud</ProjectsP>
                </ProjectsCard>
                <ProjectsCard to='sentiment'>
                    <ProjectsIcon src={data} />
                    <ProjectsH2>Sentiment Analysis</ProjectsH2>
                    <ProjectsP>Using AI to understand emotion</ProjectsP>
                </ProjectsCard>
                <ProjectsCard to='warpSpeed'>
                    <ProjectsIcon src={rocket} />
                    <ProjectsH2>Warp Speed</ProjectsH2>
                    <ProjectsP>Using Three.js (Work in progress)</ProjectsP>
                </ProjectsCard>
            </ProjectsWrapper>   
        </ProjectsContainer>
    )
}

export default Projects
