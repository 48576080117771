export const projectsObjOne = {
    id: 'atom-about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    headline: "The S Orbital",
    description: `This project uses Three.js to code a dramatasized 
    version of the atom. 1,000 "electrons" are shown orbiting the surface 
    of a sphere to help visualize an electron cloud. 
    Note: while a real S orbital only contains 2 electrons, this animation is meant to
    depict the idea that electrons have no confined orbit, but instead exist in a
    probability space where an exact position or momentum cannot be defined.`,
    buttonLabel: "Back",
    buttonTo: '/',
    imgStart: false,
    img: require('../../images/lecture.svg').default,
    alt: 'Lecture',
    dark: true,
    primary: true,
    darkText: false,
};

export const projectsObjTwo = {
    id: 'warpSpeed-about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    headline: "There are Somehow Clouds in Space",
    description: `No real science here. This was just a fun project
    flying through the clouds`,
    buttonLabel: "Back",
    buttonTo: '/',
    imgStart: false,
    img: require('../../images/lecture.svg').default,
    alt: 'Lecture',
    dark: true,
    primary: true,
    darkText: false,
};

export const projectsObjThree = {
    id: 'sentiment-about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About',
    headline: "Sentiment Analysis",
    description: <code>This project uses a neural network to perform Sentiment Analysis, or opinion/emotion analysis. 
    This is done with a recurrent neural network, a special type of feedforward neural network that maintains a kind of memory of each 
    input in a sequence. It can use this memory to help process sequences, putting the most weight on the words that are important.
    </code>,
    buttonLabel: "Back",
    buttonTo: '/',
    imgStart: false,
    img: require('../../images/lecture.svg').default,
    alt: 'Lecture',
    dark: true,
    primary: true,
    darkText: false,
};

export const projectsObjFour = {
    id: 'sentiment-about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Try for yourself',
    headline: "Examples",
    description: <code>
    Ex: "My best friend is amazing." The network would might put specific emphasis on the words "best", "friend", and "amazing" while coming to a decision 
    on emotion, the same way you might.
    <br />
    <br />
    It can also use this memory for more convoluted examples. Take negation for example: "I am not happy about the result." This is a trickier
    sentence because the meaning is negative, despite positive tonality from "happy" because of the "not" that proceeds it.
    <br />
    <br />
    Enter a sentence or word of your own and hit Analyze to give it a try.</code>,
    buttonLabel: "Try for yourself",
    buttonTo: '/sentiment',
    imgStart: true,
    img: require('../../images/scientist.svg').default,
    alt: 'Lecture',
    dark: true,
    primary: true,
    darkText: false,
};
