import React from 'react';
import {NavLogo} from './ProjectNavbarElements';
import {animateScroll as scroll} from 'react-scroll';


const ProjectsNavbar = ({toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <NavLogo to='/' onClick={toggleHome}>
            CodeChemist
        </NavLogo>
    )
}

export default ProjectsNavbar