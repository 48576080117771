import React, { Component } from 'react'
import styled from 'styled-components';
import * as THREE from 'three'
import { BloomEffect, EffectComposer, EffectPass, RenderPass, TextureEffect, KernelSize, BlendFunction } from "postprocessing";

//const postprocessing = require('postprocessing')
let cloudGeo, cloudMaterial, nClouds, cloudParticles = [], cloudZ = [], textureEffect;

class Scene extends Component {
  
  constructor(props) {
    super(props)
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)
    //this.onWindowResize = this.onWindowResize.bind(this)
  }

  componentDidMount() {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.z = 1;
    camera.rotation.x = 1.16;
    camera.rotation.y = -0.12;
    camera.rotation.z = 0.27;

    //Lighting
    let ambient = new THREE.AmbientLight(0x555555);
    scene.add(ambient);
    const renderer = new THREE.WebGLRenderer();
    //cloud coloring
    let directional = new THREE.DirectionalLight(0xffc19);
    directional.position.set(0, 0, -10);
    scene.add(directional);
    let directional2 = new THREE.DirectionalLight(0x8a08f9);
    directional2.position.set(0, 0, 10);
    scene.add(directional2);


    let light1 = new THREE.PointLight(0xcc6600, 20, 450, 1.7);
    light1.position.set(200, 300, 100);
    scene.add(light1);
    let light2 = new THREE.PointLight(0xd85473, 20, 450, 1.7);
    light2.position.set(100, 300, 100);
    scene.add(light2);
    let light3 = new THREE.PointLight(0x3677ac, 10, 450, 1.7);
    light3.position.set(300, 300, 100);
    scene.add(light3);


    renderer.setSize(window.innerWidth, window.innerHeight)
    scene.fog = new THREE.FogExp2(0x07000d, 0.003);
    renderer.setClearColor(scene.fog.color);
    //document.body.appendChild(renderer.domElement);


    //load and make cloud boxes with a texture
    let loader = new THREE.TextureLoader();
    loader.load("smoke.png", function(texture){
        cloudGeo = new THREE.PlaneBufferGeometry(500,500);
        cloudMaterial = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true
        });

        //create cloud particles
        nClouds = 50;
        for(let i=0; i<nClouds; i++) {
            let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
            cloud.position.set(
                Math.random()*1000-200,
                Math.random()*1000,
                -200,
            );
            cloud.rotation.x = 1.16;
            cloud.rotation.y = -0.12;
            cloud.rotation.z = Math.random()*2*Math.PI;
            cloud.material.opacity = 0.55;
            cloudParticles.push(cloud);
            scene.add(cloud);
            cloudZ.push(randSign());
        };
    });

    loader.load("smoke2.png", function(texture){
      cloudGeo = new THREE.PlaneBufferGeometry(500,500);
      cloudMaterial = new THREE.MeshLambertMaterial({
          map: texture,
          transparent: true
      });

      //create cloud particles
      nClouds = 50;
      for(let i=0; i<nClouds; i++) {
          let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
          cloud.position.set(
              Math.random()*1000-200,
              Math.random()*1000,
              -200,
          );
          cloud.rotation.x = 1.16;
          cloud.rotation.y = -0.12;
          cloud.rotation.z = Math.random()*2*Math.PI;
          cloud.material.opacity = 0.55;
          cloudParticles.push(cloud);
          scene.add(cloud);
          cloudZ.push(randSign());
      };
    });

    const composer = new EffectComposer(renderer, sceneContainer);

    loader.load("NightSky.jpg", function (texture) {
      textureEffect = new TextureEffect({
        blendFunction: BlendFunction.COLOR_DODGE,
        texture: texture,
      });
      //let now = new Date();
      textureEffect.blendMode.opacity.value = 3;

      const bloomEffect = new BloomEffect({
          blendFunction: BlendFunction.ADD,
          kernelSize: KernelSize.SMALL,
          useLuminanceFilter: true,
          luminanceThreshold: 0.5,
          luminanceSmoothing: 0.75,
      });
      bloomEffect.blendMode.opacity.value = 0.1;

      let effectPass = new EffectPass(
          camera,
          bloomEffect,
          textureEffect
      );
      effectPass.renderToScreen = true;

      composer.addPass(new RenderPass(scene, camera));
      composer.addPass(effectPass);
    });

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.composer = composer;

    this.mount.appendChild(this.renderer.domElement);
    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {
    cloudParticles.forEach(i => {
      i.rotation.z -= 0.0010;
      i.position.x += 0.25;
      i.position.y += 1.5;
      i.position.z += 0.005;
      if (i.position.z > -200) { i.position.z = -201 };
      if (i.position.x > 1000) { i.position.x = -200 };
      if (i.position.y > 1000) { i.position.y = 0 };
    })
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate);
  }

  renderScene() {
    this.composer.render(0.01)
  }

  render() {
    return (
      <div id="sceneContainer" 
      ref={(mount) => { this.mount = mount }}
      />
    );
  }
}

export default Scene

function randSign() {
  let sign = 1;
  let number = Math.random();
  if (number > 0.5) {
    sign = -1
  }
  return sign
};


const sceneContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: red;
`