import React from 'react'
import { Typography } from '@material-ui/core';
import { red, green, grey } from '@material-ui/core/colors';
import { TextfieldContainer, ButtonContainer } from './sentimentElements'
import { Button } from '../../IndividualProjectCode/ProjectsButtonElements';
import * as tf from '@tensorflow/tfjs';

let model, sample
let displayColor = grey[500]
let sentiment = "Awaiting Input";

(async () => {
    model = await tf.loadLayersModel("https://raw.githubusercontent.com/NealRyan/sentiment-analysis/main/model.json");
})();

const SentimentDisplay = () => {
    let input_string

    const getData = (e) => {
        input_string = e.target.value;
    }
    function clearFields() {
        document.getElementById("textfield1").value = "";
    }

    function analyzeString() {
        clearFields()
        try {
            sample = input_string.split(" ")
        } catch {
            input_string = "placeholder neutral text"
            sample = input_string.split(" ")
        }


        let word_index = require('./tokenizer_word_index.json')
        //word_index = JSON.parse(word_index)
        word_index = JSON.stringify(word_index)
        word_index = JSON.parse(word_index)
        //console.log(word_index)
        let token = []

        //recreating keras text_to_sequences
        for (var i in sample) {
            let index = findKey(sample[i])
            if (index != null) {
                token.push(index)
            }
        }

        function findKey(key) {
            for (var i in word_index) {
                if (i === key) return word_index[i]
            }
        }

        //recreating the keras pad_sequences function
        function pad_sequences(data, length){
            while (data.length < length) {
                data.unshift(0)
            }
        }

        let max_length = 100
        pad_sequences(token, max_length)
        let shape = [1, 100]
        let x = tf.tensor([token], shape)
        
        let prediction = model.predict(x)
        prediction = prediction.arraySync()[0]
        let argMax = prediction.indexOf(Math.max(...prediction))
        if (argMax === 0 ){sentiment = "Neutral"; displayColor = grey[500]}
        else if (argMax === 1 ){sentiment = "Positive"; displayColor = green[700]}
        else if (argMax === 2 ){sentiment = "Negative"; displayColor = red[300]}
        else {sentiment = "Error"; displayColor = red[900]};
    }

    return (
        <>
            <TextfieldContainer>
                <form noValidate autoComplete="off">
                    <textarea
                        classname="input-message" id="textfield1" placeholder="Input text to analyze" onChange={getData} inputProps={{ style: { background: 'white' } }}>
                    </textarea>
                </form>
            </TextfieldContainer>
            <ButtonContainer>
                <Button smooth="true"
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        primary={true ? 1 : 0}
                        dark={true ? 1 : 0}
                        dark2={false? 1 : 0} onClick={analyzeString}>
                            Analyze
                        </Button>
            </ButtonContainer>
            <Typography style={{ color: displayColor, background: '#010606' }}>
                <h2 align="center">{sentiment}</h2>
            </Typography>
        </>
    )
}

export default SentimentDisplay
